import $ from "jquery";
import Swiper from "swiper";

$(function () {
  $(".burger").on("click", function (e) {
    $(".burger").toggleClass("burger--open");
    $(".header__menu").toggleClass("header__menu--open");
  });

  $(document).on("click", "a:not(.vacancy-box,.exclusive-partners, .contacts__item-icon, .lgt, .reg__link-anchor, .crew-slider__btn, .why__btn, .reviews__btn)", function (e) {
    e.preventDefault();
    let link = $(this).attr("href");
    let currDomain = (window.location.href).toString()
      .split("//")[1]
      .split("/")[0]; // if /en
    // console.log(currDomain);
    window.location = "https://app." + currDomain + link;
  });
  $(document).on("click", "a.vacancy-box", function (e) {
    // e.preventDefault();
    let link = $(this).attr("href");
    let currDomain = (window.location.href).toString()
      .split("//")[1]
      .split("/")[0]; // if /en
    // console.log(currDomain);
    window.location = "https://app." + currDomain + link;
  });

  $(".lang").on("click", function () {
    $(".widgets__text-box").removeClass("widgets__text-box--open");
    $(this).find(".lang__current").toggleClass("lang__current--active");
    $(this).find(".lang__list").toggleClass("lang__list--open");
  });

  $(".show-video, .video-modal__close").on("click", function () {
    $(".video-modal").toggleClass("video-modal--open");
  });


  $(".widgets__icon").on("click", function () {
    $(".widgets__text-box").removeClass("widgets__text-box--open");
    $(this).next().addClass("widgets__text-box--open");
  });

  $(".widgets__text-close").on("click", function () {
    $(this).parent().removeClass("widgets__text-box--open");
  });

  $(".position__tabs-item").on("click", function () {
    $(".position__tabs-item").removeClass("position__tabs-item--active");
    $(this).addClass("position__tabs-item--active");
    if ($(this).hasClass("vacancyTab")) {
      $(".position__tab--vacancy").show();
      $(".position__vacancy-nav").show();
      $(".position__vacancy-arrows").show();
      $(".position__vacancy-counter").show();
      $("#vacancyLink").removeClass("position__link--hide");

      $(".position__tab--resume").hide();
      $(".position__resume-nav").hide();
      $(".position__resume-arrows").hide();
      $(".position__resume-counter").hide();
      $("#resumeLink").addClass("position__link--hide");

    } else {
      $(".position__tab--vacancy").hide();
      $(".position__vacancy-nav").hide();
      $(".position__vacancy-arrows").hide();
      $(".position__vacancy-counter").hide();
      $("#vacancyLink").addClass("position__link--hide");


      $(".position__resume-arrows").show();
      $(".position__tab--resume").show();
      $(".position__resume-nav").show();
      $(".position__resume-counter").show();
      $("#resumeLink").removeClass("position__link--hide");

    }
  });

  $(document).ready(function () {
    const currDomain = (window.location.href).toString().split("//")[1].split("/")[0]; // if /en
    const url = "https://api." + currDomain + "/api/v1";
    // Register a user
    fetch(`${url}/sailor_rank?` + new URLSearchParams({max_results: 500}), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(resp => resp.json())
      .then(res => {
        res._items.forEach(option => {
          const optionElement = document.createElement('option');
          optionElement.value = option._id;
          optionElement.textContent = option.rank;
          document.getElementById('rank_id').appendChild(optionElement);
        });
      }).catch(e => {});

    fetch(`${url}/public_data`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(resp => resp.json())
      .then(res => {
        if (res._status !== 'OK') return;

        $('#count-body-resume').text(res.counters.resume);
        $('#count-body-vacancie').text(res.counters.vacancie);
        $('#count-body-company').text(res.counters.company);
        $('#count-footer-resume').text(res.counters.resume);
        $('#count-footer-vacancie').text(res.counters.vacancie);
        $('#count-footer-company').text(res.counters.company);


      })


    // let mainSlider = new Swiper(".main-slider", {
    //   speed: 750,
    //   mode: "horizontal",
    //   loop: true,
    //   effect: "fade",
    //   navigation: {
    //     nextEl: ".main-slider__arrow-left",
    //     prevEl: ".main-slider__arrow-right"
    //   },
    //   pagination: {
    //     el: ".main-slider__nav",
    //     clickable: true
    //   }
    // });

    let crewSlider = new Swiper(".crew-slider", {
      speed: 750,
      mode: "horizontal",
      // effect: "fade",
      initialSlide: 1,
      // autoHeight: true,
      navigation: {
        nextEl: ".crew-slider__arrow-right",
        prevEl: ".crew-slider__arrow-left"
      },
      pagination: {
        el: ".crew-slider__nav",
        clickable: true
      }
    });
    // let isFirstLoadAdapt = true;
    // let isSailorVisible = true;

    crewSlider.on("slideChange", function (e) {
      if (crewSlider.activeIndex === 2) {
        $(".crew-slider")
          .removeClass("crew-slider--company crew-slider--sailor")
          .addClass("crew-slider--company");
        $(".crew-slider__arrow-right, .crew-slider__arrow-left").removeClass("crew-slider__arrow--hide");
        $(".crew-slider__arrow-right").addClass("crew-slider__arrow--hide");
        $(".crew-slider__arrow-left").addClass("crew-slider__arrow--indent");
      } else if (crewSlider.activeIndex === 0) {
        $(".crew-slider")
          .removeClass("crew-slider--company crew-slider--sailor")
          .addClass("crew-slider--sailor");
        $(".crew-slider__arrow-right, .crew-slider__arrow-left").removeClass("crew-slider__arrow--hide");
        $(".crew-slider__arrow-left").addClass("crew-slider__arrow--hide");
        $(".crew-slider__arrow-right").addClass("crew-slider__arrow--indent");
      } else if (crewSlider.activeIndex === 1) {

        $(".crew-slider__arrow-right, .crew-slider__arrow-left").removeClass("crew-slider__arrow--hide crew-slider__arrow--indent");
        $(".crew-slider").removeClass("crew-slider--company crew-slider--sailor");

        // first version adaptive
        // let windowWidth = $(window).width();
        //
        // if (windowWidth < 1201) {
        //   $(".crew-slider__arrow-right, .crew-slider__arrow-left").
        //     addClass("crew-slider__arrow--hide");
        //   if (isSailorVisible) {
        //     $(".crew-slider")
        //       .removeClass("crew-slider--company crew-slider--sailor")
        //       .addClass("crew-slider--sailor");
        //   } else {
        //     $(".crew-slider")
        //       .removeClass("crew-slider--company crew-slider--sailor")
        //       .addClass("crew-slider--company");
        //   }
        // } else {
        //   $(".crew-slider__arrow-right, .crew-slider__arrow-left").removeClass("crew-slider__arrow--hide crew-slider__arrow--indent");
        //   $(".crew-slider").removeClass("crew-slider--company crew-slider--sailor");
        // }

      }
    });

    // first version adaptive crew-slider
    // $(".advantages__arrow-left").on("click", function (e) {
    //   if (isSailorVisible) {
    //     crewSlider.slideTo(0, 750);
    //   } else {
    //     isSailorVisible = true;
    //     $("#companyAdvantages").hide();
    //     $("#sailorAdvantages").show();
    //     $(".crew-slider")
    //       .removeClass("crew-slider--company crew-slider--sailor")
    //       .addClass("crew-slider--sailor");
    //   }
    // });
    //
    // $(".advantages__arrow-right").on("click", function (e) {
    //   if (!isSailorVisible) {
    //     crewSlider.slideTo(2, 750);
    //   } else {
    //     isSailorVisible = false;
    //     $("#companyAdvantages").show();
    //     $("#sailorAdvantages").hide();
    //     $(".crew-slider")
    //       .removeClass("crew-slider--company crew-slider--sailor")
    //       .addClass("crew-slider--company");
    //   }
    // });

    // crewSliderAdaptive();
    // $(window).on("resize", crewSliderAdaptive);
    //
    // function crewSliderAdaptive() {
    //   let windowWidth = $(window).width();
    //   if (!!crewSlider && windowWidth < 1201) {
    //     // isFirstLoadAdapt = false;
    //     $("#companyAdvantages").hide();
    //     $("#sailorAdvantages").show();
    //     if (crewSlider.activeIndex === 1) {
    //       $(".crew-slider__arrow-right, .crew-slider__arrow-left").
    //         addClass("crew-slider__arrow--hide");
    //       $(".crew-slider")
    //         .removeClass("crew-slider--company crew-slider--sailor")
    //         .addClass("crew-slider--sailor");
    //     }
    //   } else {
    //     $(".crew-slider")
    //       .removeClass("crew-slider--company crew-slider--sailor");
    //     $("#companyAdvantages").show();
    //     $("#sailorAdvantages").show();
    //   }
    // }

    $(".step__more-mob").on("click", function () {
      let stepContainer = $(this).parent().parent();
      stepContainer.find(".step__list").removeClass("step__list--more");
      if (!$(this).hasClass("step__more-mob--open")) {
        $(this).parent().find(".step__list").addClass("step__list--more");
        stepContainer.find(".step__more-mob").removeClass("step__more-mob--open");
        $(this).addClass("step__more-mob--open");
      } else {
        stepContainer.find(".step__more-mob").removeClass("step__more-mob--open");
      }
    });

    let countVacancy = $(".position__vacancy .vacancy-box").length;
    $(".position__vacancy-counter .last").text(countVacancy);
    let typeOfVacancySlider = countVacancy > 6 ? 2 : 1;

    let vacancySlider = new Swiper(".position__vacancy", {
      speed: 200,
      slidesPerView: 3,
      slidesPerColumn: typeOfVacancySlider,
      spaceBetween: 35,
      // preventClicks: false,
      // preventClicksPropagation: false,
      autoplay: true,
      pagination: {
        el: ".position__vacancy-nav",
        clickable: true
      },
      navigation: {
        nextEl: ".position__vacancy-arrows-right",
        prevEl: ".position__vacancy-arrows-left"
      },
      breakpoints: {
        1000: {
          slidesPerView: 1,
          slidesPerColumn: 2,
          navigation: {
            nextEl: null,
            prevEl: null
          }
        }
      }
    });

    vacancySlider.on("slideChange", function () {

      if (typeOfVacancySlider === 2) {
        $(".position__vacancy-counter .first").text(2 * vacancySlider.activeIndex + 1);
        if (countVacancy % 2 === 0 || (vacancySlider.activeIndex) * 2 + 5 !== countVacancy) {
          $(".position__vacancy-counter .last").text(2 * vacancySlider.activeIndex + 6);
        } else {
          $(".position__vacancy-counter .last").text(countVacancy);
        }
      } else {
        $(".position__vacancy-counter .first").text(vacancySlider.activeIndex + 1);
        $(".position__vacancy-counter .last").text(vacancySlider.activeIndex + 3);
      }
    });

    let countResume = $(".position__resume .vacancy-box").length;
    $(".position__resume-counter .last").text(countResume);
    let typeOfResumeSlider = countResume > 6 ? 2 : 1;

    let resumeSlider = new Swiper(".position__resume", {
      mode: "horizontal",
      speed: 200,
      slidesPerView: 3,
      // loop: true,
      autoplay: true,
      // preventClicks: false,
      // preventClicksPropagation: false,
      slidesPerColumn: typeOfResumeSlider,
      spaceBetween: 35,
      pagination: {
        el: ".position__resume-nav",
        clickable: true
      },
      navigation: {
        nextEl: ".position__resume-arrows-right",
        prevEl: ".position__resume-arrows-left"
      },
      breakpoints: {
        1000: {
          slidesPerView: 1,
          slidesPerColumn: 2,
          navigation: {
            nextEl: null,
            prevEl: null
          }
        }
      }
    });

    resumeSlider.on("slideChange", function () {

      if (typeOfResumeSlider === 2) {
        $(".position__resume-counter .first").text(2 * vacancySlider.activeIndex + 1);
        console.log(countVacancy % 2);
        if (countResume % 2 === 0 || (resumeSlider.activeIndex) * 2 + 5 !== countResume) {
          $(".position__resume-counter .last").text(2 * resumeSlider.activeIndex + 6);
        } else {
          $(".position__resume-counter .last").text(countResume);
        }
      } else {
        $(".position__resume-counter .first").text(resumeSlider.activeIndex + 1);
        $(".position__resume-counter .last").text(resumeSlider.activeIndex + 3);
      }
    });

    $(".position__tab--resume").hide();
    $(".position__resume-nav").hide();
    $(".position__resume-arrows").hide();
    $(".position__resume-counter").hide();

    let companySlider = undefined;

    function initCompanySlider() {
      let screenWidth = $(window).width();

      if (screenWidth > 1000 && companySlider == undefined) {
        $(".companies-slider").addClass("swiper-container");
        $(".companies-slider__wrapper").addClass("swiper-wrapper");
        $(".companies-slider__slide").addClass("swiper-slide");
        companySlider = new Swiper('.companies-slider', {
          speed: 750,
          mode: "horizontal",
          loop: true,
          slidesPerView: 3,
          spaceBetween: 40,
          navigation: {
            nextEl: ".companies-slider__arrow-right",
            prevEl: ".companies-slider__arrow-left"
          },
          breakpoints: {
            1000: {
              slidesPerView: 1,
              spaceBetween: 0
            }
          },
        });
      } else if (screenWidth < 1001 && companySlider != undefined) {
        companySlider.destroy();
        companySlider = undefined;
        $(".companies-slider").removeClass("swiper-container");
        $(".companies-slider__wrapper").removeClass("swiper-wrapper");
        $(".companies-slider__slide").removeClass("swiper-slide");
      }
    }

    let funcSlider = undefined;

    function initFuncSlider() {
      let screenWidth = $(window).width();

      if (screenWidth < 1001 && funcSlider == undefined) {
        $(".functions__slider").addClass("swiper-container");
        $(".functions__content").addClass("swiper-wrapper");
        $(".functions__item").addClass("swiper-slide");
        funcSlider = new Swiper('.functions__slider', {
          speed: 750,
          loop: true,
          slidesPerView: 1,
          autoHeight: true,
          pagination: {
            el: ".functions__slider-nav",
            clickable: true
          },
          navigation: {
            nextEl: ".functions__slider-arrow-right",
            prevEl: ".functions__slider-arrow-left"
          },
        });
      } else if (screenWidth > 1000 && funcSlider != undefined) {
        funcSlider.destroy();
        funcSlider = undefined;
        $(".functions__slider").removeClass("swiper-container");
        $(".functions__content").removeClass("swiper-wrapper");
        $(".functions__item").removeClass("swiper-slide");
      }
    }

    let smartSlider = undefined;

    function initSmartSlider() {
      let screenWidth = $(window).width();

      if (screenWidth < 1001 && smartSlider == undefined) {
        $("#sn_3, #sn_4, #sn_5").appendTo('.smart-notifications-wrapper-left');
        $(".smart-notifications-wrapper").addClass("swiper-container");
        $(".smart-notifications-wrapper-left").addClass("swiper-wrapper");
        $(".sn_item").addClass("swiper-slide");
        smartSlider = new Swiper('.smart-notifications-wrapper', {
          speed: 750,
          //loop: true,
          slidesPerView: 1,
          autoHeight: true,
          autoplay: true,
          //autoplay: {
          // delay: 15000,
          //},
          pagination: {
            el: ".smart-notifications-wrapper-nav",
            clickable: true
          },
          navigation: {
            nextEl: ".smart-notifications-wrapper-arrow-right",
            prevEl: ".smart-notifications-wrapper-arrow-left"
          },
        });
        smartSlider.on('slideChangeTransitionEnd', function () {
          var index = smartSlider.realIndex;
          //var index =$(this)[0];
          //console.log(index);
          $('#sn_' + index).click();
          smartSlider.autoplay.start();
        });
      } else if (screenWidth > 1000 && smartSlider != undefined) {
        smartSlider.destroy();
        smartSlider = undefined;
        $("#sn_3, #sn_4, #sn_5").appendTo('.smart-notifications-wrapper-right');
        $(".smart-notifications-wrapper").removeClass("swiper-container");
        $(".smart-notifications-wrapper-left").removeClass("swiper-wrapper");
        $(".sn_item").removeClass("swiper-slide");
      }
    }

    initSmartSlider();
    initFuncSlider();
    initCompanySlider();

    $(window).on('resize', function () {
      initFuncSlider();
      initCompanySlider();
      initSmartSlider();
    });

    // timer

    function initializeTimer() {

      // month == month - 1 !!!
      const DATE_FIHISH = new Date(2019, 7, 12).getTime();
      const DATE_NOW = Date.now();

      var time,
        different = false;

      time = (DATE_FIHISH - DATE_NOW) / 1000;

      localStorage.setItem("time", time);
      localStorage.setItem("heytimer", true);
      localStorage.setItem("different", different);

      timerSettings();
    }

    function timerSettings() {
      var time = localStorage.getItem('time'),
        different = localStorage.getItem('different') === "true",
        days = parseInt(time / 86400, 10),
        hours = parseInt((time - days * 86400) / 3600, 10),
        minutes = parseInt((time - (days * 86400 + hours * 3600)) / 60, 10),
        seconds = parseInt(time % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : "" + minutes;
      seconds = seconds < 10 ? "0" + seconds : "" + seconds;
      hours = hours < 10 ? "0" + hours : "" + hours;

      var daysHTML = document.getElementsByClassName("days");
      var hoursHTML = document.getElementsByClassName("hours");
      var minutesHTML = document.getElementsByClassName("minutes");
      var secondsHTML = document.getElementsByClassName("seconds");

      if (--time < 0) {
        localStorage.removeItem("heytimer");
        return;
      }
      if (different) {
        seconds = seconds.split("");
        minutes = minutes.split("");
        hours = hours.split("");
        days = days.split("");

        doubleFilling(daysHTML, days);
        doubleFilling(hoursHTML, hours);
        doubleFilling(minutesHTML, minutes);
        doubleFilling(secondsHTML, seconds);
      } else {
        filling(daysHTML, days);
        filling(hoursHTML, hours);
        filling(minutesHTML, minutes);
        filling(secondsHTML, seconds);
      }

      localStorage.setItem("time", time);
      setTimeout(timerSettings, 1000);
    }

    function filling(obj, value) {
      for (var i = 0; i < obj.length; i++) {
        obj[i].innerHTML = value;
      }
    }

    function doubleFilling(obj, value) {
      for (var i = 0; i < obj.length; i++) {
        obj[i].innerHTML = value[i % 2];
      }
    }

    var h = document.querySelector('.hours'), m = document.querySelector('.minutes'),
      s = document.querySelector('.seconds'), d = document.querySelector('.days');

    if (h && m && d) {
      // если все значения (часы/минуты/дни) сущесвтуют, тогда срабатывает таймер
      initializeTimer();
    }

//smart_notifications

    var vid = document.getElementById("video-sn_0");
    let screenWidth = $(window).width();

    if (vid) {
      vid.ontimeupdate = currentFeature;
    }
    var timeArray = [0, 15.5, 31, 46.5, 72, 86];

    function currentFeature(e) {
      var x = vid.currentTime;
      // console.log(x);
      for (var i = 0; i < timeArray.length; i++) {
        var time = i !== timeArray.length - 1 ?
          x >= timeArray[i] && x < timeArray[i + 1] : x >= timeArray[i];
        var el = $('#sn_' + i);
        if (!el.hasClass('.active_sn_item') && time) {
          //console.log (x)
          $(".sn_item").removeClass("active_sn_item");
          el.addClass("active_sn_item");
        }

      }

    }

    $('.sn_item').each(function (i, el) {
      // console.log("each_i:"+i);
      $(this).on('click', function () {
        $('.sn_item').removeClass('active_sn_item');
        var id_Name = $(this).attr('id');
        var idName = 'sn_' + (i);
        //console.log ("id"+idName+"----id_attr="+id_Name);
        $('#' + id_Name).addClass('active_sn_item');
        //let video = $('#video-sn_1')[0];
        //video['currentTime'] = timeArray[i];
        vid.currentTime = timeArray[i];

      });
    });


  });

});

window.addEventListener("load", function () {
  let reviewsSlider = new Swiper(".reviews-slider", {
    speed: 750,
    mode: "horizontal",
    loop: true,
    autoplay: true,
    slidesPerView: 3,
    preventClicks: false,
    preventClicksPropagation: false,
    spaceBetween: 40,
    navigation: {
      nextEl: ".reviews-slider__arrow-right",
      prevEl: ".reviews-slider__arrow-left"
    },
    breakpoints: {
      1000: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".reviews-slider__nav",
          clickable: true
        }
      }
    },
  });

  let exclusiveSlider = new Swiper(".main-partners__wrapper", {
    speed: 750,
    mode: "horizontal",
    spaceBetween: 10,
    slidesPerView: 1,
    loop: true,
    autoplay: true,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      nextEl: ".main-partners__arrow-right",
      prevEl: ".main-partners__arrow-left"
    }
  });
  $("#btn-subscribe").click(function () {
    let email = document.getElementById('mail').value;
    if (email && email.length > 0) {
      document.getElementById('mail').value = "";
      let currDomain = (window.location.href).toString().split("//")[1].split("/")[0]; // if /en
      var url = "https://api." + currDomain + "/api/v1/subscribe";
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          var j_resp = JSON.parse(xhr.responseText);
          if (j_resp['_status'] == 'OK') {
            alert('Success subscribed!')
          } else {
            let msg = j_resp['_error']['message'];
            var el = document.createElement('div');
            el.innerHTML = j_resp['_error']['message'];
            if (el.firstChild && el.firstChild.firstChild) {
              msg = el.firstChild.firstChild.textContent;
            }
            alert(msg);
          }
        }
      };
      var data = JSON.stringify({"email": email});
      xhr.send(data);
    }
  });

  const validateField = (field) => {
    field.val() ? field.removeClass('invalid') : field.addClass('invalid');
  }

  $("#reg-submit").click(async () => {
    const loadingAnimation = $('#loadingAnimation');
    const loadingOverlay = $('#loadingOverlay');
    const username = $('#username');
    const password = $('#password');
    const confirmPassword = $('#confirm-password');
    const confirmPasswordValidation = $('#confirm-password_validation');
    const phone = $('#phone');
    const rank = $('#rank_id');
    const regexPattern = /^\+\d{2,20}$/;

    validateField(username);
    validateField(password);
    validateField(confirmPassword);
    validateField(phone);
    validateField(rank);

    if (confirmPassword && password && confirmPassword.val() !== password.val()) {
      confirmPasswordValidation.css('display', 'inline-block');
      confirmPassword.addClass('invalid');
    } else {
      confirmPasswordValidation.css('display', 'none');
      confirmPassword.removeClass('invalid');
    }

    if (!regexPattern.test(phone.val())) {
      phone.addClass('invalid');
    } else {
      phone.removeClass('invalid');
    }

    if (username.val() && password.val() && confirmPassword.val() === password.val() && phone.val() && rank.val() && regexPattern.test(phone.val())) {
      try {
        loadingAnimation.removeClass("hidden");
        loadingOverlay.removeClass("hidden")
        const currDomain = (window.location.href).toString().split("//")[1].split("/")[0]; // if /en
        const url = "https://api." + currDomain + "/api/v1";
        // Register a user
        const resp = await fetch(`${url}/user`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({username: username.val(), password: password.val(), role: 'sailor'})
        });

        const userResult = await resp.json();
        if (userResult._status === 'ERR') {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = userResult._error.message;
          loadingAnimation.addClass("hidden");
          loadingOverlay.addClass("hidden")
          alert(tempElement.textContent ? tempElement.textContent : 'Something went wrong');
          return;
        }

        // Get auth token for profile patch
        const auth = await fetch(`${url}/auth`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({username: username.val(), password: password.val()})
        });
        const authData = await auth.json();

        if (authData._status === 'ERR') {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = userResult._error.message;
          loadingAnimation.addClass("hidden");
          loadingOverlay.addClass("hidden")
          alert(tempElement.textContent ? tempElement.textContent : 'Something went wrong');
          return;
        }

        // Patch sailor details
        const result = await fetch(`${url}/register_sailor`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authData.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "need_use_presailor": false,
            "sailor_contacts": {
              "email": username.val(),
              "phone": {
                "mobile": phone.val()
              }
            },
            "professional_details": {
              "_sailor_rank_id": rank.val()
            }
          })
        });
        const reqStatus = await result.json();
        if (authData._status === 'ERR') {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = userResult._error.message;
          loadingAnimation.addClass("hidden");
          loadingOverlay.addClass("hidden")
          alert(tempElement.textContent ? tempElement.textContent : 'Something went wrong');
          return;
        }

        if (reqStatus._status === 'OK') {
          loadingAnimation.addClass("hidden");
          loadingOverlay.addClass("hidden")
          username.val('');
          password.val('');
          confirmPassword.val('');
          phone.val('');
          rank.val('');
          window.location.href = '/congrats';
        }
      } catch (e) {
        loadingAnimation.addClass("hidden");
        loadingOverlay.addClass("hidden")
        alert('Something went wrong');
      }
    }
  });

});